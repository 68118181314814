import React, { PureComponent } from 'react';
import { Link, navigate } from "@reach/router";
import { withTranslation } from "react-i18next";

import {
  getDirectorDetailsPageRoute,
  getMovieDetailsPageRoute,
  getStarDetailsRoute,
  getStudioDetailsRoute,
  getThemeDetailsRoute,
  getSexActDetailsRoute
} from "../../services/navigation/navigation.service.routes";
import { searchHome } from "../../services/search-service/search.service";

import { LoadingController } from "../../controllers/loading-controller/loading.controller";
import { MainDashboardController } from "../../pages/MainDashBoard/MainDashboardController";

import DropdownSelect from '../DropdownMenu/DropdownSelect';
import { ReactComponent as SearchIcon } from '../../images/NS_search_icon.svg';

import './MainSearch.scss';

class MainSearch extends PureComponent {
  searchInputRef = React.createRef();
  searchInProgress = false;
  searchResultsLoaded = false;
  searchTimeoutId;
  lastRequestController = null;
  initialDropdownValue = { name: 'All', tag: 'all' };

  state = {
    directors: [],
    movies: [],
    studios: [],
    stars: [],
    categories: [],
    sex_acts: [],
    searchValue: '',
    selectedDropdownValue: this.initialDropdownValue
  };

  subscriptions = {};

  componentWillUnmount() {
    this.isUnMounted = true;
    this.abortLastRequest();
    if (this.searchTimeoutId) {
      clearTimeout(this.searchTimeoutId);
    }
  }

  abortLastRequest = () => {
    if (this.lastRequestController) {
      this.lastRequestController.abort();
    }
  }

  doSearch = () => {
    const { searchValue, selectedDropdownValue } = this.state;
    const filter = selectedDropdownValue?.tag !== this.initialDropdownValue.tag ? selectedDropdownValue.tag : '';
    this.searchResultsLoaded = false;

    if (searchValue) {
      const { result, controller } = searchHome(searchValue, filter);
      this.abortLastRequest();
      this.lastRequestController = controller;
      result.then(this.loadSearchResults).catch(this.onRequestFailure);
    }
  };

  loadSearchResults = (response) => {
    this.searchInProgress = false;
    const { directors, movies, stars, studios, categories, sex_acts } = response.data.data;
    if (!this.isUnMounted) {
      this.setState({
        directors, movies, stars, studios, categories, sex_acts
      });
    }
    this.searchResultsLoaded = true;
  };

  onLinkClick = (e) => {
    this.resetSearchResults();
    let fn;
    switch (e.target.dataset.entity) {
      case 'movies':
        fn = MainDashboardController.notifyMovieChange;
        break;
      case 'stars':
        fn = MainDashboardController.notifyStarChange;
        break;
      case 'studios':
        fn = MainDashboardController.notifyStudioChange;
        break;
      case 'categories':
      case 'sex_acts':
        fn = MainDashboardController.notifyThemeChange;
        break;
      case 'directors':
        fn = MainDashboardController.notifyDirectorChange;
        break;
      default:
        break;
    }
    if (fn) {
      setTimeout(fn, 100);
    }
  };

  onRequestFailure = () => {
    LoadingController.removeLoadingMask();
    this.searchInProgress = false;
  };

  onSearchValueChange = (event) => {
    this.searchInProgress = true;
    const { value } = event.target;
    this.setState({ searchValue: value });
    if (this.searchTimeoutId) {
      clearTimeout(this.searchTimeoutId);
    }
    this.searchTimeoutId = setTimeout(this.doSearch, 500);
  };

  onChangeSearchSection = (selected) => {
    const { handleSearchDropdownValue } = this.props;

    this.setState({
      selectedDropdownValue: selected,
      directors: [],
      movies: [],
      studios: [],
      stars: [],
      categories: [],
      sex_acts: []
    }, handleSearchDropdownValue(selected.tag));
    this.searchInputRef.current.focus();
  };

  clearSearchValue = () => {
    this.setState({ searchValue: "" });
  }

  renderSearchEntityResults = (data = [], getRouteFn, entity) => {
    let view = null;
    if (data.length) {
      const { t } = this.props;
      view = (
        <div className="SearchResultsSection">
          <div className="SearchResultsSectionHeader">{t(`MainSearch.${entity}`)}</div>
          {data.map(this.renderSearchResultItem.bind(this, getRouteFn, entity))}
        </div>
      );
    }

    return view;
  };

  renderSearchResultItem = (getRouteFn, entity, { id, name, title, titleNs }) => {
    let text = titleNs || title || name;
    return (
      <Link className="SearchResultItem"
        to={getRouteFn(id, text)}
        replace={true}
        data-entity={entity}
        onClick={this.onLinkClick}
        key={`${id}-${name}`}>
        {text}
      </Link>
    );
  };

  renderSearchResults = () => {
    const { directors = [], movies = [], searchValue, studios = [], stars = [], categories = [], sex_acts = [] } = this.state;
    let view = null;
    if (searchValue) {
      const hasItems = directors.length || movies.length || studios.length || stars.length || categories.length || sex_acts.length;
      if (hasItems) {
        view = (
          <div className="SearchResults">
            {this.renderSearchEntityResults(movies, getMovieDetailsPageRoute, 'movies')}
            {this.renderSearchEntityResults(studios, getStudioDetailsRoute, 'studios')}
            {this.renderSearchEntityResults(stars, getStarDetailsRoute, 'stars')}
            {this.renderSearchEntityResults(categories, getThemeDetailsRoute, 'categories')}
            {this.renderSearchEntityResults(sex_acts, getSexActDetailsRoute, 'sex_acts')}
            {this.renderSearchEntityResults(directors, getDirectorDetailsPageRoute, 'directors')}
          </div>
        );
      } else if (!this.searchInProgress) {
        view = (
          <div className="NoResults">
            {this.props.t('MainSearch.noResults')}
          </div>
        );
      }
    }

    return view;
  };

  resetSearchResults = () => {
    this.searchInProgress = false;
    this.setState({
      directors: [],
      movies: [],
      searchValue: '',
      studios: [],
      stars: [],
      categories: [],
      sex_acts: [],
      selectedDropdownValue: this.initialDropdownValue
    })
  };

  handleFullSearchRedirect = (event) => {
    const { searchValue } = this.state;
    const { key } = event;

    if (searchValue.trim() === '') return;

    if (key === 'Enter' && searchValue) {
      this.abortLastRequest();
      navigate(`/search?q=${searchValue}`);
      this.resetSearchResults();
    } else if (key === 'Escape') {
      this.abortLastRequest();
      this.resetSearchResults();
    }
  }

  render() {
    const { searchOptions = [] } = this.props;
    const { searchValue, selectedDropdownValue } = this.state;
    const searchResults = this.renderSearchResults();
    const showOnlyCheckedOptions = searchOptions.filter(item => item?.checked || item.tag === this.initialDropdownValue.tag);
    const hasDropdownOptions = showOnlyCheckedOptions.length > 1; // the 'All' option excluded

    return (
      <div className='MainSearch'>
        <div className='SearchItems'>
          {hasDropdownOptions && (
            <DropdownSelect
              keyToUse={"SearchDropdown"}
              options={showOnlyCheckedOptions}
              onChangeSearchSection={this.onChangeSearchSection}
              clearSearchValue={this.clearSearchValue}
              selectedDropdownValue={selectedDropdownValue}
            />
          )}

          <div className='SearchInputContainer'>
            <input
              name="q"
              autoFocus={true}
              type="text"
              ref={this.searchInputRef}
              className={"searchTerm"}
              value={searchValue}
              onChange={this.onSearchValueChange}
              onKeyUp={this.handleFullSearchRedirect}
              placeholder='Search...'
            />
            <span className='SearchIcon'><SearchIcon /></span>
            {searchResults}

          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(MainSearch);