import React from 'react';
import useUserStateChange from '../../hook/useUserStateChange';
import { Link } from "@reach/router";
import PropTypes from 'prop-types';
import { isExternalLink } from '../../services/util-service/util.service';
import { routes } from '../../services/navigation/navigation.service.routes';
import { ReactComponent as AngleDownIcon } from '../../images/NS_angle_down.svg';

import './BrowseNavigation.scss';

const BrowseNavigation = (props) => {
  const [logged] = useUserStateChange();
  const { browseConfig = [], className, starsPageEnabled, baseText } = props;
  const renderItem = ({
    link,
    text,
    open_in_new_window: openInNewWindow,
    logged_in = true,
    logged_out = true
  }) => {
    let view = null;
    let fullLink = link.startsWith('/') ? link : `/${link}`;
    const isExternal = isExternalLink(link);
    const isStarsPage = fullLink === routes.stars;

    if (isExternal) {
      fullLink = link;
    }

    if (isStarsPage && !starsPageEnabled) {
      return view;
    }

    if (!((logged && logged_in) || (!logged && logged_out))) {
      return view;
    }

    const key = `BrowseNavigationLink-${link}`;
    if (openInNewWindow || isExternal) {
      view = (
        <a
          className={"Item"}
          href={fullLink}
          rel={"noopener noreferrer nofollow"}
          key={key}
          target={openInNewWindow ? '_blank' : null}
        >
          {text}
        </a>
      );
    } else {
      view = (
        <Link className="Item"
          key={key}
          to={fullLink}>
          {text}
        </Link>
      );
    }

    return view;
  };

  return (
    <div className="BrowseNavigation">
      <div className={className}>
        {baseText}  <AngleDownIcon />
      </div>
      <div className="dropdown-content">
        {browseConfig?.map(renderItem)}
      </div>
    </div>
  );
};

export default BrowseNavigation;

BrowseNavigation.propTypes = {
  className: PropTypes.string,
  browseConfig: PropTypes.array,
  starsPageEnabled: PropTypes.number,
  baseText: PropTypes.string
};
