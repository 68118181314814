import React, { useState, useEffect, useRef } from 'react';
import { Link, navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';

import ApplicationLogo from '../../../components/ApplicationLogo/ApplicationLogo';
import BrowseNavigation from '../../../components/BrowseNavigation/BrowseNavigation';
import MainSearch from '../../../components/MainSearch/MainSearch';
import UserAction from '../../../components/UserAction/UserAction';
import { PROPERTY_ID, RenderConditionalComponent } from '../../../components/RenderConditionalComponent/RenderConditionalComponent';
import { ReactComponent as AgeVerifiedIcon } from '../../../images/ageVerifiedIcon.svg';
import { ReactComponent as SearchIcon } from '../../../images/NS_search_icon.svg';

import { getOriginalsPageRoute, routes } from '../../../services/navigation/navigation.service.routes';
import { createJoinLinkFromLastCode, links } from '../../../links';
import { isUserLoggedIn } from '../../../services/token-service/token.service';
import { getUserNATSCode } from '../../../services/nats-service/nats.service';
import { UsersController } from '../../../controllers/users-controller/users.controller';
import { showMyAccountPage } from '../../../services/navigation/navigation.service';
import { SignInController } from '../../../controllers/sign-in-controller/sign-in.controller';
import { getCookie, isWeb } from '../../../services/util-service/util.service';

import './MainDashboardHeader.scss';

const MainDashboardHeader = (props) => {
  const {
    browseConfig = [],
    starsPageEnabled,
    searchOptions,
    handleSearchDropdownValue,
    toggleMobileMenu
  } = props;

  const [desktopSearchVisible, setDesktopSearchVisible] = useState(false);
  const ageVerContentRef = useRef(null);
  const ageVerVisible = useRef(false);
  const { t } = useTranslation();
  const userLoggedIn = isUserLoggedIn();

  useEffect(() => {
    const onClickOutside = (event) => {
      const elem = event.target;

      // search
      if (elem.classList.contains('SearchIcon')) {
        return;
      } else if ((elem.closest('.MainSearch')?.className !== 'MainSearch')) {
        setDesktopSearchVisible(false);
      }

      // age verification badge
      if (isWeb()) return;
      if (ageVerContentRef.current) {
        if (elem.classList.contains('AgeVerifiedContainer')) {
          ageVerVisible.current = !ageVerVisible.current;
          ageVerContentRef.current.style.display = ageVerVisible.current ? 'block' : 'none';
        } else {
          ageVerContentRef.current.style.display = 'none';
          ageVerVisible.current = false;
        }
      }
    };
    document.addEventListener('click', onClickOutside);

    return () => {
      document.removeEventListener('click', onClickOutside);
    }
  }, []);

  const renderNav = () => {
    const navLinks = "NavBar-Btn";

    return (
      <nav className='NavBar d-flex align-center'>
        <>
          {browseConfig?.length !== 0 && (
            <BrowseNavigation
              className={navLinks}
              browseConfig={browseConfig}
              starsPageEnabled={starsPageEnabled}
              baseText='Videos'
            />
          )}

          <Link className={navLinks} to={routes.stars}>Stars</Link>
          <Link className={navLinks} to={routes.studios}>Studios</Link>
          <a
            className={navLinks}
            href='https://www.nakedswordlive.com/'
            target='_blank'
            rel="noreferrer">Live Cams</a>
          <a
            className={navLinks}
            href="https://vod.nakedsword.com/gay?campaignId=19178&subAffId=NSVODHOME"
            target='_blank'
            rel="noreferrer"
          >
            On Demand
          </a>

          <RenderConditionalComponent ids={[PROPERTY_ID.guerillaPorn, PROPERTY_ID.huntForMen]}>
            <Link
              className={navLinks}
              key="originals"
              to={getOriginalsPageRoute()}>
              {t('MainDashboardHeader.originals')}
            </Link>
          </RenderConditionalComponent>

          <RenderConditionalComponent ids={[PROPERTY_ID.trentonDucati]}>
            <BrowseNavigation
              className={navLinks}
              browseConfig={[
                {
                  link: routes.trentonducatioriginals,
                  text: t("MainDashboardHeader.tdoriginals")
                },
                {
                  link: getOriginalsPageRoute(),
                  text: t('MainDashboardHeader.nsoriginals')
                }]}
              baseText={t('MainDashboardHeader.originals')}
            />
          </RenderConditionalComponent>

          <RenderConditionalComponent ids={[PROPERTY_ID.huntForMen]}>
            <a className={navLinks}
              href={links.zine}
              rel="noopener noreferrer"
              target="_blank">
              HUNT eZine
            </a>
          </RenderConditionalComponent>

          <RenderConditionalComponent ids={[PROPERTY_ID.guerillaPorn, PROPERTY_ID.trentonDucati]}>
            <a className={navLinks}
              href={links.live}
              rel="noopener noreferrer nofollow"
              target="_blank">
              {t('MainDashboardHeader.live')}
            </a>
          </RenderConditionalComponent>
        </>
      </nav>
    )
  };

  const renderSearch = () => {
    if (!desktopSearchVisible) return null;

    return (
      <MainSearch
        searchOptions={searchOptions}
        handleSearchDropdownValue={handleSearchDropdownValue}
      />
    )
  };

  const renderUserActions = () => {
    return (
      <div className="UserActions">
        {userLoggedIn && (
          <>
            <UserAction text={t('MainDashboardHeader.myAccount')} onClick={() => showMyAccountPage()} />
            <div onClick={() => UsersController.signOutUser(t('MainDashboardHeader.loginOutMsg'))} className="LoginWrapper">
              <UserAction text={t('MainDashboardHeader.signOut')} />
            </div>
          </>
        )}
        {!userLoggedIn && (
          <>
            <UserAction text='Join Now' onClick={() => {
              // get latest valid code
              const getStoredCode = getUserNATSCode();
              const joinURL = createJoinLinkFromLastCode(getStoredCode);
              navigate(joinURL);
            }} />
            <div onClick={() => SignInController.showSignInDialog()} className="LoginWrapper">
              <UserAction text='Sign in' />
            </div>
          </>
        )}
      </div>
    )
  };

  const renderAgeVerIcon = () => {
    let bavCookie = getCookie('bav');
    if (!bavCookie) return null;

    try {
      bavCookie = decodeURIComponent(bavCookie);
      bavCookie = window.atob(bavCookie);
    } catch (err) {
      console.error('Decoding bav failed:', err);
    }
    const bavToLocalFormat = new Date(bavCookie * 1000).toLocaleString(); // timestamp  
    const validBavDate = bavToLocalFormat !== 'Invalid Date' ? bavToLocalFormat : '';

    return (
      <span className='AgeVerifiedContainer'>
        <AgeVerifiedIcon />
        <div className='AgeVerifiedContent' ref={ageVerContentRef}>
          <div>Age verification successful.</div>
          <div>Verification valid until:</div>
          <div>{validBavDate}</div>
        </div>
      </span>
    )
  };

  const renderMobileIcons = () => {
    return (
      <i className="MobileMenuBtn fa fa-bars cursor-pointer" onClick={() => {
        if (toggleMobileMenu()) {
          toggleMobileMenu();
        }
        setDesktopSearchVisible(false);
      }} />
    )
  };

  const renderSearchIcon = () => {
    return (
      <div className="SearchIcon d-flex align-center justify-center" onClick={() => {
        if (SignInController.isSignInDisplayed()) {
          SignInController.closeSignInDialog();
        }
        setDesktopSearchVisible(!desktopSearchVisible)
      }}>
        {desktopSearchVisible ? <i className='fa fa-times' style={{ opacity: '.5' }}></i> : <SearchIcon />}
      </div>
    )
  };

  return (
    <header className='MainDashboardHeader d-flex align-center'>
      <ApplicationLogo />
      {renderNav()} {/* hidden in mobile view*/}

      <div className='d-flex align-center'>
        {renderSearchIcon()}
        {renderMobileIcons()}
        {renderUserActions()}  {/* hidden in mobile view*/}
        {renderAgeVerIcon()}
      </div>

      {renderSearch()}
    </header>
  )
};

MainDashboardHeader.displayName = 'MainDashboardHeader';

export default MainDashboardHeader;
